
import craftGraphqlApiClient from '../plugins/CraftGraphqlApiClient.js';
import { defineStore } from 'pinia';
import { router } from '../router.js';

import gql_mutation_login from '../graphql/mutation/Login.gql';
import gql_mutation_logout from '../graphql/mutation/Logout.gql';
import gql_mutation_refresh_token from '../graphql/mutation/RefreshToken.gql';
import gql_mutation_request_password_reset from '../graphql/mutation/RequestPasswordReset.gql';
import gql_mutation_user_photo from '../graphql/mutation/UserPhoto.gql';
import gql_mutation_user_profile from '../graphql/mutation/UserProfile.gql';
import gql_mutation_update_password from '../graphql/mutation/UpdatePassword.gql';
import gql_mutation_subscribed_projects from '../graphql/mutation/SubscribedProjects.gql';
import gql_mutation_email_notification_projects from '../graphql/mutation/EmailNotificationProjects.gql';
import gql_mutation_set_project_status from '../graphql/mutation/ProjectStatus.gql';
import gql_mutation_set_asset_status from '../graphql/mutation/AssetStatus.gql';

import gql_query_approval_globals from '../graphql/query/ApprovalGlobals.gql';
import gql_query_asset_by_id from '../graphql/query/AssetById.gql';
import gql_query_assets_by_project_id from '../graphql/query/AssetsByProjectId.gql';
import gql_query_option_comments_by_option_id from '../graphql/query/OptionCommentsByOptionId.gql';
import gql_query_discussion_comments_by_discussion_id from '../graphql/query/DiscussionCommentsByDiscussionId.gql';
import gql_query_discussions_by_project_id from '../graphql/query/DiscussionsByProjectId.gql';
import gql_query_login_globals from '../graphql/query/LoginGlobals.gql';
import gql_query_option_by_option_id from '../graphql/query/OptionByOptionId.gql';
import gql_query_projects from '../graphql/query/Projects.gql';
import gql_query_project_titles from '../graphql/query/ProjectTitles.gql';
import gql_query_project_by_id from '../graphql/query/ProjectById.gql';
import gql_query_project_status_by_id from '../graphql/query/ProjectStatusById.gql';
import gql_query_projects_by_user from '../graphql/query/ProjectsByUser.gql';
import gql_query_example_projects from '../graphql/query/ExampleProjects.gql';
import gql_query_revision_by_revision_id from '../graphql/query/RevisionByRevisionId.gql';
import gql_query_clients from '../graphql/query/Clients.gql';
import gql_query_staff from '../graphql/query/Staff.gql';
import gql_query_staff_admins from '../graphql/query/StaffAdmins.gql';
import gql_query_staff_super_admins from '../graphql/query/StaffSuperAdmins.gql';
import gql_query_boundary_staff from '../graphql/query/BoundaryStaff.gql';
import gql_query_freelancers from '../graphql/query/Freelancers.gql';
import gql_query_submissions_by_project_id from '../graphql/query/SubmissionsByProjectId.gql';
import gql_query_submission_by_id from '../graphql/query/SubmissionById.gql';
import gql_query_media_types from '../graphql/query/MediaTypes.gql';
import gql_query_viewer from '../graphql/query/Viewer.gql';
import gql_query_project_tags_by_title from '../graphql/query/ProjectTagsByTitle.gql';
import gql_query_common_project_tags from '../graphql/query/CommonProjectTags.gql';
import gql_query_database_tags_by_title from '../graphql/query/DatabaseTagsByTitle.gql';
import gql_query_database_tags_by_search from '../graphql/query/DatabaseTagsBySearch.gql';
import gql_query_common_database_tags from '../graphql/query/CommonDatabaseTags.gql';
import gql_query_database_options from '../graphql/query/DatabaseOptions.gql';
import gql_query_database_option_by_id from '../graphql/query/DatabaseOptionById.gql';
import gql_query_notifications from '../graphql/query/Notifications.gql';
import gql_query_pending_notifications_count from '../graphql/query/PendingNotificationsCount.gql';
import gql_query_unread_comment_counts_by_project_id from '../graphql/query/UnreadCommentCountsByProjectId.gql';
import gql_query_option_likes from '../graphql/query/OptionLikes.gql';
import gql_query_liked_options from '../graphql/query/LikedOptions.gql';
import gql_query_my_boundary_pages from '../graphql/query/MyBoundaryPages.gql';
import gql_query_my_boundary_page_by_slug from '../graphql/query/MyBoundaryPageBySlug.gql';
import gql_query_onboarding_slideshows from '../graphql/query/OnboardingSlideshows.gql';
import gql_query_documentation_pages from '../graphql/query/DocumentationPages.gql';
import gql_query_abc_work_stages from '../graphql/query/ABCWorkStages.gql';

import gql_mutation_project from '../graphql/mutation/Project.gql';
import gql_mutation_asset from '../graphql/mutation/Asset.gql';
import gql_mutation_delete_asset from '../graphql/mutation/DeleteAsset.gql';
import gql_mutation_set_project_thumbnail from '../graphql/mutation/ProjectThumbnail.gql';
import gql_mutation_client_approve_option from '../graphql/mutation/ClientApproveOption.gql';
import gql_mutation_client_approve_final_revision from '../graphql/mutation/ClientApproveFinalRevision.gql';
import gql_mutation_staff_approve_option from '../graphql/mutation/StaffApproveOption.gql';
import gql_mutation_delete_comment from '../graphql/mutation/DeleteComment.gql';
import gql_mutation_discussion from '../graphql/mutation/Discussion.gql';
import gql_mutation_explore_option from '../graphql/mutation/ExploreOption.gql';
import gql_mutation_post_discussion_comment from '../graphql/mutation/PostDiscussionComment.gql';
import gql_mutation_post_option_comment from '../graphql/mutation/PostOptionComment.gql';
import gql_mutation_submission from '../graphql/mutation/Submission.gql';
import gql_mutation_toggle_favourite_project from '../graphql/mutation/ToggleFavouriteProject.gql';
import gql_mutation_create_archive from '../graphql/mutation/CreateArchive.gql';
import gql_mutation_create_attachments_archive from '../graphql/mutation/CreateAttachmentsArchive.gql';
import gql_mutation_project_tag from '../graphql/mutation/ProjectTag.gql';
import gql_mutation_save_tags_to_project from '../graphql/mutation/SaveTagsToProject.gql';
import gql_mutation_database_tag from '../graphql/mutation/DatabaseTag.gql';
import gql_mutation_save_tags_to_image_option from '../graphql/mutation/SaveTagsToImageOption.gql';
import gql_mutation_save_tags_to_video_option from '../graphql/mutation/SaveTagsToVideoOption.gql';
import gql_mutation_save_tags_to_explore_option from '../graphql/mutation/SaveTagsToExploreOption.gql';
import gql_mutation_toggle_include_in_database_image_option from '../graphql/mutation/ToggleIncludeInDatabaseImageOption.gql';
import gql_mutation_toggle_include_in_database_video_option from '../graphql/mutation/ToggleIncludeInDatabaseVideoOption.gql';
import gql_mutation_toggle_include_in_database_explore_option from '../graphql/mutation/ToggleIncludeInDatabaseExploreOption.gql';
import gql_mutation_toggle_published_image_option from '../graphql/mutation/TogglePublishedImageOption.gql';
import gql_mutation_toggle_published_video_option from '../graphql/mutation/TogglePublishedVideoOption.gql';
import gql_mutation_toggle_published_explore_option from '../graphql/mutation/TogglePublishedExploreOption.gql';
import gql_mutation_read_notification from '../graphql/mutation/ReadNotification.gql';
import gql_mutation_read_notifications from '../graphql/mutation/ReadNotifications.gql';
import gql_mutation_like_option from '../graphql/mutation/LikeOption.gql';
import gql_mutation_unlike_option from '../graphql/mutation/UnlikeOption.gql';
import gql_mutation_save_users_to_project from '../graphql/mutation/SaveUsersToProject.gql';
import gql_mutation_create_submission_notification from '../graphql/mutation/SubmissionNotification.gql';
import gql_mutation_delete_option from '../graphql/mutation/DeleteOption.gql';
import gql_mutation_completed_project from '../graphql/mutation/ToggleCompletedProject.gql';

import {
	USER_GROUP_CLIENT,
	USER_GROUP_FREELANCER,
	USER_GROUP_STAFF,
} from '../constants';

function buildAuthHeader( user_data ) {
	return {
		Authorization: 'JWT ' + user_data.jwt
	};
}

function transformLoginGlobalsData( data ) {
	data.title = data.login_title;
	data.copy = data.login_copy;
	data.background_video_url = data.login_background_video_url;
	data.background_poster_image = data.login_background_video_poster_image[0] || null;
	return data;
}

function transformApprovalGlobalsData( data ) {
	data.global_approvers = data.global_approvers || null;
	return data;
}

function transformMediaTypes( data ) {
	const types = {};

	data.forEach( ( type ) => {
		types[type.id] = {
			id: type.id,
			title: type.title,
			base_type: type.base_type,
			slug: type.slug,
		};
	} );

	return types;
}

export const useDataStore = defineStore( 'data', { // eslint-disable-line snakecasejs/snakecasejs
	persist: true,
	state: () => {
		return {
			expand_nav: true,
			breadcrumbs: null,
			user_data: null,
			user_profile: null,
			login_globals: null,
			approval_globals: null,
			media_types: [],
			common_project_locations: [],
			alert_message: null,
			active_projects_tab: 'my_projects',
			project_sorting: {
				selected_sort_order: 'title',
				title_sort_order: 'DESC',
				date_sort_order: 'DESC'
			},
			all_notifications: [],
			all_notifications_pagination: {
				page: 1,
				per_page: 10,
				user_related_pending_count: 0,
				pending_count: 0,
				total_count: 0,
			},
			all_notifications_filters: [
				{ slug: 'discussions', label: 'Discussions', active: true },
				{ slug: 'uploads', label: 'Asset uploads', active: true },
				{ slug: 'client_approvals', label: 'Approvals', active: true },
				{ slug: 'submissions', label: 'Submissions', active: true }
			],
			loading_first_page_notifications: false
		};
	},
	actions: {
		async authenticatedQuery( query, variables ) {
			const unix_now = Date.now();
			if ( !this.user_data ) {
				router.push( { name: 'login' } );
			}
			if ( this.user_data && this.user_data.jwtExpiresAt < unix_now ) {
				if ( this.user_data.refreshTokenExpiresAt < unix_now ) {
					router.push( {
						name: 'login',
						query: {
							expired: 1
						}
					} );
					return;
				}
				try {
					const response = await craftGraphqlApiClient.query(
						gql_mutation_refresh_token,
						{ refresh_token: this.user_data.refreshToken }
					);
					if ( response.errors ) {
						router.push( {
							name: 'login',
							query: {
								expired: 1
							}
						} );
						throw ( response.errors[0] );
					}
					this.user_data = response.data.refreshToken;
				} catch ( error ) {
					return error;
				}
			}
			try {
				const response = await craftGraphqlApiClient.query( query, variables, buildAuthHeader( this.user_data ) );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async login( data ) {
			const store = useDataStore();
			const response = await craftGraphqlApiClient.query( gql_mutation_login, data );
			if ( response.errors ) {
				return { error: response.errors[0].message };
			}
			this.user_data = response.data.authenticate;
			await store.getUserProfile();
			return true;
		},
		async logout() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_logout );
				this.user_data = null;
				this.user_profile = null;
				return response;
			} catch( error ) {
				return error;
			}
		},
		async requestPasswordReset( email ) {
			const response = await craftGraphqlApiClient.query( gql_mutation_request_password_reset, { email } );
			if ( response.errors ) {
				return { error: response.errors[0].message };
			}
			return response;
		},
		async updatePassword( data ) {
			const store = useDataStore()
			const response = await store.authenticatedQuery( gql_mutation_update_password, data );
			if ( response.errors ) {
				return { error: response.errors[0].message };
			}
			return response;
		},
		async getUserProfile() {
			const store = useDataStore();
			const response = await store.authenticatedQuery( gql_query_viewer );
			if ( response.errors ) {
				return { error: response.errors[0].message };
			}
			if ( response.data && response.data.viewer ) {
				this.user_profile = response.data.viewer;
			}
		},
		async updateUserProfile( data ) {
			const store = useDataStore();
			const response = await store.authenticatedQuery( gql_mutation_user_profile, data );
			if ( response.errors ) {
				return { error: response.errors[0].message };
			}
			return response;
		},
		async addUserPhoto( data ) {
			const store = useDataStore();
			const response = await store.authenticatedQuery( gql_mutation_user_photo, data );
			if ( response.errors ) {
				return { error: response.errors[0].message };
			}
			return response;
		},
		async getStaffUsers() { // Boundary staff + freelancers
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_staff );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getBoundaryStaffUsers( ids = null ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_boundary_staff, { ids: ids } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getFreelancerUsers() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_freelancers );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getClientUsers() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_clients );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getLoginGlobals() {
			try {
				const response = await craftGraphqlApiClient.query( gql_query_login_globals, {} );
				this.login_globals = transformLoginGlobalsData( response.data.globalSet );
			} catch ( error ) {
				return error;
			}
		},
		async getApprovalGlobals() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_approval_globals, {} );
				this.approval_globals = transformApprovalGlobalsData( response.data.globalSet );
			} catch ( error ) {
				return error;
			}
		},
		async getProjectTitles() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_project_titles );
				return response.data.entries;
			} catch ( error ) {
				return error;
			}
		},
		async setSubscribedProjects( project_ids ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_subscribed_projects, {
					project_ids
				} );
				if ( response.data && response.data.updateViewer ) {
					this.user_profile = response.data.updateViewer;
				}
			} catch ( error ) {
				return error;
			}
		},
		async setEmailNotifications( notification_projects_ids ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_email_notification_projects, {
					notification_projects_ids
				} );
				if ( response.data && response.data.updateViewer ) {
					this.user_profile = response.data.updateViewer;
				}
			} catch ( error ) {
				return error;
			}
		},
		async getProjects( data ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_projects, data );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getUserRelatedProjects( data ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_projects_by_user, data );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getExampleProjects() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_example_projects );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getProjectById( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_project_by_id, { id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getAssetsByProjectId( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_assets_by_project_id, { project_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getAssetById( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_asset_by_id, { id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async createAsset( { project, asset_title, media_type_id } ) {
			const store = useDataStore();
			try {
				const asset_response = await store.authenticatedQuery( gql_mutation_asset, {
					title: asset_title,
					media_type: media_type_id
				} );

				const new_asset = asset_response.data && asset_response.data.save_project_assets_default_Entry ? asset_response.data.save_project_assets_default_Entry : null;

				if ( new_asset ) {
					const project_response = await store.authenticatedQuery( gql_mutation_project, {
						id: parseInt( project.id ),
						project_assets: [ ...project.project_assets.map( asset => parseInt( asset.id ) ), parseInt( new_asset.id ) ]
					} );

					if ( project_response.data && project_response.data.save_projects_default_Entry) {
						return new_asset;
					}
				}
			} catch ( error ) {
				return error;
			}
		},
		async setProjectThumbnail( { project_id, asset_id } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_set_project_thumbnail, {
					project_id: project_id,
					project_thumbnail: asset_id ? [ asset_id ] : []
				} );

				return response;
			} catch ( error ) {
				return error;
			}
		},
		async setProjectStatus( { project_id, project_status } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_set_project_status, {
					project_id: project_id,
					project_status: project_status
				} );

				return response;
			} catch ( error ) {
				return error;
			}
		},
		async setAssetStatus( { asset_id, asset_status } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_set_asset_status, {
					asset_id: asset_id,
					asset_status: asset_status
				} );

				return response;
			} catch ( error ) {
				return error;
			}
		},
		async deleteAsset( { asset_id } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_delete_asset, {
					asset_id: asset_id
				} );

				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getUnreadCommentCountsByProjectId( project_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_unread_comment_counts_by_project_id, { project_id: project_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async createExploreOption( data ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_explore_option, data );
				return response && response.data ? response.data.createExploreOption : null;
			} catch ( error ) {
				return error;
			}
		},
		async getDiscussionsByProjectId( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_discussions_by_project_id, { project_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getSubmissionsByProjectId( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_submissions_by_project_id, { project_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getSubmissionById( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_submission_by_id, { project_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async createSubmission( data ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_submission, data );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async saveUsersToProject( data ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_save_users_to_project, data );
				return response;
			} catch( error ) {
				return error;
			}
		},
		async getOptionByOptionId( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_option_by_option_id, { option_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getRevisionByRevisionId( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_revision_by_revision_id, { revision_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async createDiscussion( data ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_discussion, data );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getCommentsByDiscussionId( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_discussion_comments_by_discussion_id, { discussion_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getCommentsByOptionId( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_option_comments_by_option_id, { option_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async postComment( data ) {
			const store = useDataStore();
			try {
				const mutation = data.comment_type === 'option' ? gql_mutation_post_option_comment : gql_mutation_post_discussion_comment;
				const response = await store.authenticatedQuery( mutation, data );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async deleteComment( id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_delete_comment, { comment_id: id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async deleteOption( { option_id } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_delete_option, { option_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async staffApproveOption( option_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_staff_approve_option, { option_id: option_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async clientApproveOption( option_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_client_approve_option, { option_id: option_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async clientApproveFinalRevision( revision_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_client_approve_final_revision, { revision_id: revision_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async toggleFavouriteProject( project_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_toggle_favourite_project, { project_id: project_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async createArchive( { option_ids, project_id, submission_id = null } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_create_archive, {
					option_ids: option_ids,
					project_id: project_id,
					submission_id: submission_id
				} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async createAttachmentsArchive( { comment_id } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_create_attachments_archive, {
					comment_id: comment_id
				} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getMediaTypes() {
			try {
				const response = await craftGraphqlApiClient.query( gql_query_media_types, { ids: null } );
				this.media_types = transformMediaTypes( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getProjectTagsByTitle( title ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_project_tags_by_title, { title: title } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getCommonProjectTags() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_common_project_tags );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async saveProjectTag( title ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_project_tag, { title: title } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async saveTagsToProject( { project_id, tags } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_save_tags_to_project, { project_id: project_id, project_tags: tags } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getDatabaseTagsByTitle( title ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_database_tags_by_title, { title: title } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getDatabaseTagsBySearch( search ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_database_tags_by_search, { search: search } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async saveDatabaseTag( title ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_database_tag, { title: title } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async saveTagsToOption( { option_id, tags, asset_type } ) {
			const store = useDataStore();
			const mutations_by_asset_type = {
				image: gql_mutation_save_tags_to_image_option,
				video: gql_mutation_save_tags_to_video_option,
				explore: gql_mutation_save_tags_to_explore_option
			}

			try {
				const response = await store.authenticatedQuery( mutations_by_asset_type[asset_type], { option_id: option_id, database_tags: tags } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getCommonDatabaseTags() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_common_database_tags );
				if ( response && response.data ) {
					return response.data.globalSet.common_database_tags;
				}
			} catch ( error ) {
				return error;
			}
		},
		async toggleIncludeInDatabaseOption( { option_id, project_id, include_in_database, asset_type } ) {
			const store = useDataStore();
			const mutations_by_asset_type = {
				image: gql_mutation_toggle_include_in_database_image_option,
				video: gql_mutation_toggle_include_in_database_video_option,
				explore: gql_mutation_toggle_include_in_database_explore_option
			}

			try {
				const response = await store.authenticatedQuery( mutations_by_asset_type[asset_type], {
					option_id: option_id,
					project_id: project_id,
					include_in_database: include_in_database
				} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async togglePublishedOption( { option_id, published, asset_type } ) {
			const store = useDataStore();
			const mutations_by_asset_type = {
				image: gql_mutation_toggle_published_image_option,
				video: gql_mutation_toggle_published_video_option,
				explore: gql_mutation_toggle_published_explore_option
			}

			try {
				const response = await store.authenticatedQuery( mutations_by_asset_type[asset_type], {
					option_id: option_id,
					published: published
				} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getDatabaseOptions( data ) {
			const store = useDataStore();
			const timestamp = new Date().toString();
			try {
				const response = await store.authenticatedQuery( gql_query_database_options, {
					...data,
					timestamp
			 	} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getDatabaseOptionById( option_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_database_option_by_id, { option_id: option_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async toggleCompletedProject( { project_id, completed } ) {
			const store = useDataStore();

			try {
				const response = await store.authenticatedQuery( gql_mutation_completed_project, {
					project_id: project_id,
					completed: completed
				} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		setNotificationFilter( slug, active ) {
			const index = this.all_notifications_filters.indexOf( this.all_notifications_filters.find( filter => filter.slug === slug ) );
			this.all_notifications_filters[index].active = active;
		},
		resetNotificationFilters() {
			this.all_notifications_filters.forEach( ( filter, index ) => {
				this.all_notifications_filters[index].active = true;
			} );
		},
		async getAllNotifications( { user_related = null, page, per_page } ) {
			const store = useDataStore();
			const timestamp = new Date().toString();
			if ( page === 1 ) {
				this.loading_first_page_notifications = true;
				this.all_notifications = [];
				this.all_notifications_pagination.pending_count = 0;
				this.all_notifications_pagination.total_count = 0;
			}
			try {
				const response = await store.authenticatedQuery( gql_query_notifications, {
					types: store.getFormattedNotificationTypes(),
					user_related,
					page,
					per_page,
					timestamp
				} );
				if ( response.data && response.data.notifications?.length ) {
					if ( page > 1 ) {
						this.all_notifications.push( ...response.data.notifications );
					} else {
						this.all_notifications = response.data.notifications;
					}
					this.all_notifications_pagination.page = page;
					this.all_notifications_pagination.per_page = per_page;
					this.all_notifications_pagination.pending_count = response.data.pendingNotificationsTotal;
					this.all_notifications_pagination.total_count = response.data.notificationsTotal;
				}
				this.loading_first_page_notifications = false;
			} catch ( error ) {
				this.loading_first_page_notifications = false;
				return error;
			}
		},
		async getUserRelatedNotificationsCount() {
			const store = useDataStore();
			const timestamp = new Date().toString();
			try {
				const response = await store.authenticatedQuery( gql_query_pending_notifications_count, {
					project_ids: null,
					types: null,
					user_related: true,
					type: null,
					timestamp
				} );
				this.all_notifications_pagination.user_related_pending_count = response.data.pendingNotificationsTotal;
			} catch ( error ) {
				return error;
			}
		},
		async markAllNotificationsAsRead( { user_related = false } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_read_notifications, {
					types: store.getFormattedNotificationTypes(),
					user_related
				} );
				if ( !response || !response.data) {
					return;
				}
				this.all_notifications = [ ...this.all_notifications ].map( notification => {
					return {
						...notification,
						read: true
					}
				} );
				this.all_notifications_pagination.pending_count = 0;
				this.getUserRelatedNotificationsCount();
				return response.data;
			} catch ( error ) {
				return error;
			}
		},
		async getNotifications( { project_ids = null, types = null, user_related = false, page, per_page } ) {
			const store = useDataStore();
			const timestamp = new Date().toString();
			try {
				const response = await store.authenticatedQuery( gql_query_notifications, {
					project_ids,
					types,
					user_related,
					page,
					per_page,
					timestamp
				} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getPendingNotificationsCount( { project_ids = null, types = null, user_related = false } ) {
			const store = useDataStore();
			const timestamp = new Date().toString();
			try {
				const response = await store.authenticatedQuery( gql_query_pending_notifications_count, {
					project_ids,
					types,
					user_related,
					timestamp
				} );
				return response.data.pendingNotificationsTotal;
			} catch ( error ) {
				return error;
			}
		},
		async markNotificationAsRead( notification_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_read_notification, { notification_id: notification_id } );
				if ( !response || !response.data ) {
					return;
				}
				const notification = this.all_notifications.find( notification => notification.id === notification_id );
				if ( notification ) {
					this.all_notifications[ this.all_notifications.indexOf( notification ) ].read = true;
				}
				this.all_notifications_pagination.pending_count = this.all_notifications_pagination.pending_count - 1;
				this.getUserRelatedNotificationsCount();
				return response.data;
			} catch ( error ) {
				return error;
			}
		},
		async markNotificationsAsRead( { project_ids = null, types = null, user_related = false } ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_read_notifications, {
					project_ids,
					types,
					user_related
				} );
				if ( !response || !response.data) {
					return;
				}
				this.getUserRelatedNotificationsCount();
				return response.data;
			} catch ( error ) {
				return error;
			}
		},
		async createSubmissionNotification( submission_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_create_submission_notification, { submission_id: submission_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async isProjectCompleted( project_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_project_status_by_id, {
					id: project_id
				} );
				if ( !response && !response.data && !response.data.entry ) {
					return false;
				}
				return response.data.entry.project_status === 'completed';
			} catch ( error ) {
				return error;
			}
		},
		async isUserStaffAdmin() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_staff_admins );
				return !!response.data.users.find( user => parseInt( user.id ) === parseInt( this.user_profile.id ) );
			} catch ( error ) {
				return error;
			}
		},
		async isUserStaffSuperAdmin() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_staff_super_admins );
				return !!response.data.users.find( user => parseInt( user.id ) === parseInt( this.user_profile.id ) );
			} catch ( error ) {
				return error;
			}
		},
		async getOptionLikes( option_id ) {
			const store = useDataStore();
			const timestamp = new Date().toString();
			try {
				const response = await store.authenticatedQuery( gql_query_option_likes, {
					option_id,
					timestamp
				} );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getLikedOptions() {
			const store = useDataStore();
			const timestamp = new Date().toString();
			try {
				const response = await store.authenticatedQuery( gql_query_liked_options, { timestamp } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async likeOption( option_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_like_option, { option_id: option_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async unlikeOption( option_id ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_mutation_unlike_option, { option_id: option_id } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getMyBoundaryPages() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_my_boundary_pages );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getMyBoundaryPageBySlug( slug ) {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_my_boundary_page_by_slug, { slug } );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getOnboardingSlideshows() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_onboarding_slideshows );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getDocumentationPages() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_documentation_pages );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		async getABCWorkStages() {
			const store = useDataStore();
			try {
				const response = await store.authenticatedQuery( gql_query_abc_work_stages );
				return response;
			} catch ( error ) {
				return error;
			}
		},
		toggleNavExpand() {
			this.expand_nav = !this.expand_nav;
		},
		setBreadcrumbs( data ) {
			this.breadcrumbs = data;
		},
		setAlertMessage( data ) {
			this.alert_message = data;
		},
		getFormattedNotificationTypes() {
			const formatted_types = [];
			const types = {
				'discussions': ['OptionCommentPosted', 'DiscussionCommentPosted', 'DiscussionCreated'],
				'client_approvals': ['OptionClientApproved', 'RevisionClientApproved'],
				'uploads': ['UploadAdded'],
				'submissions': ['SubmissionCreated']
			};
			this.all_notifications_filters
				.filter( filter => this.user_group === USER_GROUP_STAFF ? filter.slug !== 'submissions' : ![ 'client_approvals', 'uploads' ].includes( filter.slug ) )
				.forEach( filter => {
					if ( filter.active === true ) {
						formatted_types.push( ...types[ filter.slug ] )
					}
				}
			);
			return formatted_types;
		},
	},
	getters: {
		user_group: ( state ) => {
			if ( !state.user_data ) {
				return null;
			}
			switch ( state.user_data.schema ) {
				case 'Client Schema':
					return USER_GROUP_CLIENT;
				case 'Freelancer Schema':
					return USER_GROUP_FREELANCER;
				case 'Staff Schema':
					return USER_GROUP_STAFF;
				default:
					return null;
			}
		}
	}
} );
