<template>
	<div
		class="upload-modal-wrapper"
		:class="{ 'upload-modal-wrapper--show': show }"
	>
		<div
			v-if="asset && !uploading_to_virtual_tour_grid && !stage_selected"
			class="upload-modal upload-modal--stage-selection"
		>
			<button
				class="upload-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="upload-modal__content">
				<h2 class="upload-modal__title">
					What do you want to upload?
				</h2>

				<fieldset class="form__radiogroup mb-24">
					<legend class="invisible">Stage</legend>
					<label
						v-for="uploadable_stage in uploadable_stages"
						:key="'stage-' + uploadable_stage"
						:for="value"
						class="form__radio"
					>
						<input
							type="radio"
							:id="uploadable_stage"
							name="project-status"
							:value="uploadable_stage"
							v-model="stage"
						/>
						{{ uploadable_stage }}
					</label>
				</fieldset>

				<div class="upload-modal__buttons mb-16">
					<button
						class="button"
						:disabled="loading"
						@click="closeModal"
					>
						Cancel
					</button>
					<button
						class="button button--fill"
						:class="{ 'button--loading' : loading }"
						:disabled="loading || !stage"
						@click="stage_selected = true"
					>
						Save
					</button>
				</div>
				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>
		</div>

		<div
			v-else
			class="upload-modal"
		>
			<button
				class="upload-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<Transition name="fade">
				<div
					v-if="upload_format === null"
					class="upload-modal__format-selection"
				>
					<h2 class="upload-modal__format-selection-title">
						Upload Media files
					</h2>
					<p class="upload-modal__format-selection-subtitle">
						Please select the media you wish to upload here
					</p>

					<div
						v-if="uploading_to_virtual_tour_grid"
						class="upload-modal__format-buttons"
					>
						<template
							v-for="media_type in available_media_types"
							:key="'type-' + media_type.slug"
						>
							<template v-if="media_type.slug === 'virtual-tour'">
								<button
									class="upload-modal__format-button upload-modal__format-button--virtual-tour"
									@click="selectVirtualTour( media_type, UPLOAD_FORMAT_VIRTUAL_EXPLORE_TOUR )"
								>
									Virtual Tour
								</button>
								<button
									class="upload-modal__format-button upload-modal__format-button--animations"
									@click="selectVirtualTour( media_type, UPLOAD_FORMAT_VIRTUAL_VIDEO_TOUR )"
								>
									Virtual Tour
								</button>
							</template>
						</template>

						<button
							class="upload-modal__format-button upload-modal__format-button--stills"
							@click="upload_format = UPLOAD_FORMAT_VIRTUAL_TOUR_IMAGE"
						>
							Stills
						</button>

						<button
							class="upload-modal__format-button upload-modal__format-button--animations"
							@click="upload_format = UPLOAD_FORMAT_VIRTUAL_TOUR_VIDEO"
						>
							Animations
						</button>
					</div>

					<div
						v-else
						class="upload-modal__format-buttons"
					>
						<template
							v-for="media_type in available_media_types"
							:key="'type-' + media_type.slug"
						>
							<button
								v-if="media_type.slug !== 'virtual-tour'"
								class="upload-modal__format-button"
								:class="'upload-modal__format-button--' + media_type.slug"
								@click="selectFormat( media_type )"
							>
								{{ media_type.title }}
							</button>
							<template v-else>
								<button
									class="upload-modal__format-button upload-modal__format-button--virtual-tour"
									@click="selectVirtualTour( media_type, UPLOAD_FORMAT_VIRTUAL_EXPLORE_TOUR )"
								>
									Virtual Tour
								</button>
								<button
									class="upload-modal__format-button upload-modal__format-button--animations"
									@click="selectVirtualTour( media_type, UPLOAD_FORMAT_VIRTUAL_VIDEO_TOUR )"
								>
									Virtual Tour
								</button>
							</template>
						</template>
					</div>
				</div>

				<ImageUploadComponent
					v-else-if="is_image_upload"
					:project="project"
					:asset="asset"
					:stage="stage"
					:format-locked="format_locked"
					:selected-media-type-id="selected_media_type_id"
					@back-to-format-select="upload_format = null"
				/>

				<VideoUploadComponent
					v-else-if="is_video_upload || is_virtual_video_tour_upload"
					:project="project"
					:asset="asset"
					:stage="stage"
					:format="upload_format"
					:selected-media-type-id="selected_media_type_id"
					:format-locked="format_locked"
					@back-to-format-select="upload_format = null"
				/>

				<ExploreUploadComponent
					v-else-if="is_virtual_explore_tour_upload"
					:project="project"
					:asset="asset"
					:stage="stage"
					:format="upload_format"
					:selected-media-type-id="selected_media_type_id"
					:format-locked="format_locked"
					@back-to-format-select="upload_format = null"
				/>

				<VirtualTourImageUploadComponent
					v-else-if="is_virtual_tour_image_upload"
					:project="project"
					:asset="asset"
					@back-to-format-select="upload_format = null"
				/>

				<VirtualTourVideoUploadComponent
					v-else-if="is_virtual_tour_video_upload"
					:project="project"
					:asset="asset"
					@back-to-format-select="upload_format = null"
				/>
			</Transition>
		</div>
	</div>
</template>

<script setup>
import ExploreUploadComponent from './ExploreUploadComponent.vue';
import ImageUploadComponent from './ImageUploadComponent.vue';
import VideoUploadComponent from './VideoUploadComponent.vue';
import VirtualTourImageUploadComponent from './VirtualTourImageUploadComponent.vue';
import VirtualTourVideoUploadComponent from './VirtualTourVideoUploadComponent.vue';
import {
	UPLOAD_FORMAT_IMAGE,
	UPLOAD_FORMAT_VIDEO,
	UPLOAD_FORMAT_VIRTUAL_EXPLORE_TOUR,
	UPLOAD_FORMAT_VIRTUAL_VIDEO_TOUR,
	UPLOAD_FORMAT_VIRTUAL_TOUR_IMAGE,
	UPLOAD_FORMAT_VIRTUAL_TOUR_VIDEO,
} from '../../constants';

import { useDataStore } from '../../stores/data';
import { ref, computed, defineProps, defineEmits, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

const data_store = useDataStore();

const props = defineProps( {
	show: { required: true, type: Boolean },
	project: { required: true, type: Object },
	asset: { required: false, type: Object }
} );

const emit = defineEmits( ['close-modal'] );

const route = useRoute();

const show = computed( () => props.show );
const project = computed( () => props.project );
const asset = computed( () => props.asset );

const { media_types } = storeToRefs( data_store );
const available_media_types = computed( () => {
	if ( asset.value ) {
		return Object.values( media_types.value ).filter( ( media_type ) => {
			return media_type.id === asset.value.media_type[0].id;
		} );
	}

	const project_media_types = project.value.project_assets.map( ( asset ) => {
		return asset.media_type[0].id;
	} );

	return Object.values( media_types.value ).filter( ( media_type ) => {
		return project_media_types.includes( media_type.id );
	} );
} );

const uploading_to_virtual_tour_grid = computed( () => asset.value && route.query && route.query.virtual_tour );

const uploadable_stages = computed( () => {
	if ( !asset.value || asset.value.final_revision.length ) {
		return null;
	}
	if ( !asset.value.asset_revisions.length ) {
		return ['A1'];
	}
	const latest_stage = asset.value.asset_revisions.at( -1 );

	let revision_number = latest_stage.revision_number.slice( 1 );
	if ( revision_number === '' ) {
		revision_number = 1;
	}

	if ( latest_stage.revision_number[0].toUpperCase() == 'A' ) {
		return [
			'A' + ( parseInt( revision_number ) + 1 ),
			'B1'
		]
	}

	return [ 'B' + ( parseInt( revision_number ) + 1 ) ];
} );

const upload_format = ref( null );
const selected_media_type_id = ref( null );
const format_locked = ref( false );
const stage = ref( null );
const stage_selected = ref( null );

// const is_draft_upload = computed ( () => {
// 	return upload_format.value === UPLOAD_FORMAT_DRAFT
// } );
const is_virtual_explore_tour_upload = computed( () => {
	return upload_format.value === UPLOAD_FORMAT_VIRTUAL_EXPLORE_TOUR;
} );
const is_virtual_video_tour_upload = computed( () => {
	return upload_format.value === UPLOAD_FORMAT_VIRTUAL_VIDEO_TOUR;
} );
const is_virtual_tour_image_upload = computed( () => {
	return upload_format.value === UPLOAD_FORMAT_VIRTUAL_TOUR_IMAGE;
} );
const is_virtual_tour_video_upload = computed( () => {
	return upload_format.value === UPLOAD_FORMAT_VIRTUAL_TOUR_VIDEO;
} );
const is_image_upload = computed ( () => {
	return upload_format.value === UPLOAD_FORMAT_IMAGE;
} );
const is_video_upload = computed ( () => {
	return upload_format.value === UPLOAD_FORMAT_VIDEO;
} );

function selectFormat( media_type ) {
	selected_media_type_id.value = media_type.id;
	const base_type = media_type.base_type;
	switch ( base_type ) {
		case 'image':
			upload_format.value = UPLOAD_FORMAT_IMAGE;
			break;
		case 'video':
			upload_format.value = UPLOAD_FORMAT_VIDEO;
			break;
		default:
			upload_format.value = null;
	}
}

function selectVirtualTour( media_type, virtual_tour_format ) {
	selected_media_type_id.value =  media_type.id;
	upload_format.value = virtual_tour_format;
}

function closeModal() {
	emit( 'close-modal' );
	stage.value = null;
	stage_selected.value = false;

	if ( !format_locked.value ) {
		upload_format.value = null;
	}
}

onMounted( () => {
	if ( !asset.value || !asset.value.media_type.length || uploading_to_virtual_tour_grid || asset.value.media_type[0].slug === 'virtual-tour' ) {
		return;
	}
	selectFormat( asset.value.media_type[0] );
	format_locked.value = true;
} );
</script>