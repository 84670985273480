import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "tab-navigation__tab-count"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["tab-navigation", [
			$options.theme_class ? $options.theme_class : '',
			{ 'tab-navigation--disabled': $props.disabled },
			{ 'tab-navigation--reduced-margin': $props.reducedMargin },
			{ 'tab-navigation--no-margin': $props.noMargin },
			{ 'tab-navigation--slim': $props.slim },
		]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: 'tab-' + index,
        class: _normalizeClass(["tab-navigation__tab", { 'tab-navigation__tab--active': tab.slug === $props.activeTab }])
      }, [
        _createElementVNode("button", {
          onClick: $event => (_ctx.$emit( 'set-tab', tab.slug ))
        }, [
          _createTextVNode(_toDisplayString(tab.label) + " ", 1 /* TEXT */),
          (tab.count)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(tab.count), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_1)
      ], 2 /* CLASS */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}