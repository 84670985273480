
import { createApp, nextTick } from 'vue';
import App from './App.vue';
import { router } from './router.js';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import craftGraphqlApiClient from './plugins/CraftGraphqlApiClient.js';
import { axios_instance } from './helpers/axios';
import { updatePageTitle } from '../helpers.js';
import iframeResize from 'iframe-resizer/js/iframeResizer';


const pinia = createPinia();
pinia.use( piniaPluginPersistedstate );

const app = createApp( App );

const project_route_names = [ 'projects__project', 'projects__project__asset' ];

router.afterEach( (to, from) => {
	// Ignore query param changes or project <-> asset
	if ( to.name === from.name || ( project_route_names.includes( to.name ) && project_route_names.includes( from.name ) ) ) {
		return;
	}
	nextTick( () => {
		to.meta && to.meta.title ? updatePageTitle( to.meta.title ) : updatePageTitle( 'The Boundary Live' );
	} );
} );

app.use( router );
app.use( pinia );

app.use( craftGraphqlApiClient, {
	base_url: '/api',
} );

app.config.unwrapInjectedRef = true; // Not required after Vue 3.3.
app.config.globalProperties.$axios = { ...axios_instance };

app.directive( 'resize', {
	mounted: function( el, binding ) {
		el.addEventListener( 'load', () => iframeResize( binding.value, el ) );
	},
	unmounted: function ( el ) {
		el.iFrameResizer.removeListeners();
	}
} );

const mount_point = document.querySelector( '#app' );
if ( mount_point ) {
	const tl_key = mount_point.getAttribute( 'data-tl-key' );
	if ( tl_key ) {
		app.provide( 'tl_key', tl_key );
	}
	const tl_draft_template_id = mount_point.getAttribute( 'data-tl-draft-template-id' );
	const tl_asset_template_id = mount_point.getAttribute( 'data-tl-asset-template-id' );
	const tl_video_template_id = mount_point.getAttribute( 'data-tl-video-template-id' );
	const tl_virtual_tour_image_template_id = mount_point.getAttribute( 'data-tl-virtual-tour-image-template-id' );
	const tl_virtual_tour_video_template_id = mount_point.getAttribute( 'data-tl-virtual-tour-video-template-id' );
	const tl_attachment_template_id = mount_point.getAttribute( 'data-tl-attachment-template-id' );
	const tl_explore_thumbnail_template_id = mount_point.getAttribute( 'data-tl-explore-thumbnail-template-id' );
	if ( tl_draft_template_id ) {
		app.provide( 'tl_draft_template_id', tl_draft_template_id );
	}
	if ( tl_asset_template_id ) {
		app.provide( 'tl_asset_template_id', tl_asset_template_id );
	}
	if ( tl_video_template_id ) {
		app.provide( 'tl_video_template_id', tl_video_template_id );
	}
	if ( tl_virtual_tour_image_template_id ) {
		app.provide( 'tl_virtual_tour_image_template_id', tl_virtual_tour_image_template_id );
	}
	if ( tl_virtual_tour_video_template_id ) {
		app.provide( 'tl_virtual_tour_video_template_id', tl_virtual_tour_video_template_id );
	}
	if ( tl_attachment_template_id ) {
		app.provide( 'tl_attachment_template_id', tl_attachment_template_id );
	}
	if ( tl_explore_thumbnail_template_id ) {
		app.provide( 'tl_explore_thumbnail_template_id', tl_explore_thumbnail_template_id );
	}

	app.mount( '#app' );
}
