<template>
	<tr>
		<td>
			<div
				class="inline-thumbnail-upload"
				:class="[
					{ 'inline-thumbnail-upload--hover': hover },
					{ 'inline-thumbnail-upload--error': errors && errors.thumbnail }
				]"
			>
				<drag-drop
					v-if="uppy && !Object.keys(files).length"
					:uppy="uppy"
					:props="uppy_config"
				/>
				<template v-if="Object.keys(files).length">
					<div
						v-for="( file, file_id ) in files"
						:key="file_id"
						class="inline-thumbnail-upload__file-container"
						>
						<div class="inline-thumbnail-upload__file">
							<img
								v-if="file.preview"
								class="inline-thumbnail-upload__thumbnail"
								:src="file.preview"
								:alt="file.filename"
							>
							<button
								class="inline-thumbnail-upload__remove-file"
								:class="{ 'inline-thumbnail-upload__remove-file--disabled': upload_complete }"
								:disabled="upload_complete"
								@click="removeFile( file_id )"
							/>
						</div>
						<p class="inline-thumbnail-upload__filename">
							{{ file.filename }}
						</p>
					</div>
				</template>
			</div>
		</td>
		<td>
			<template v-if="asset || explore_id">
				{{ assetsList.find( asset => asset.value === entry.asset_id ).label }}
			</template>
			<div
				v-else
				class="form__set form__set--no-margin"
				:class="{ 'form__set--error': errors && errors.asset_id }"
			>
				<div class="form__icon form__icon--down">
					<select
						v-model="entry.asset_id"
						class="form__select form__select--stretch"
						:disabled="!!explore_id"
					>
						<option
							v-for="project_asset in assetsList"
							:key="`asset-${ project_asset.value }`"
							:value="project_asset.value"
						>
							{{ project_asset.label }}
						</option>
					</select>
				</div>
			</div>
		</td>

		<td>
			<template v-if="explore_id">
				{{ entry.tour_id }}
			</template>
			<div
				v-else
				class="form__set form__set--prefix form__set--no-margin"
				:class="{ 'form__set--error': errors && errors.tour_id }"
			>
				<input
					v-model="entry.tour_id"
					class="form__input form__input--stretch"
					type="text"
					:disabled="!!explore_id"
					placeholder="e.g. cm3x8uz3e0001kb8jujco03r9"
					@blur="formatTourId"
				>
			</div>
		</td>

		<td>
			<template v-if="stage || explore_id">
				{{ entry.revision_number }}
			</template>
			<div
				v-else
				class="form__set form__set--no-margin"
				:class="{ 'form__set--error': errors && errors.revision_number }"
			>
				<input
					v-model="entry.revision_number"
					class="form__input form__input--stretch"
					type="text"
					:disabled="!!explore_id"
				>
			</div>
		</td>
		<td>
			<template v-if="explore_id">
				{{ staffList.find( user => user.value === entry.artist_id ).label }}
			</template>
			<div
				v-else
				class="form__icon form__icon--down"
			>
				<select
					v-model="entry.artist_id"
					class="form__select form__select--stretch"
					:class="{ 'form__select--error': errors && errors.artist_id }"
					:disabled="!!explore_id || !staffList || !staffList.length"
				>
					<option
						v-for="user in staffList"
						:key="`submission-${ user.value }`"
						:value="user.value"
					>
						{{ user.label }}
					</option>
					<option
						v-if="!staffList || !staffList.length"
						value=""
					>
						No artists on project
					</option>
				</select>
				<button
					v-if="entry.artist_id && entries.length > 1"
					class="form__copy-value"
					@click="$emit( 'copy-artist-to-all', entry.artist_id )"
				>
					Select artist for all
				</button>
			</div>
		</td>
		<td>
			<button
				v-if="canRemove && !upload_complete"
				class="upload-modal__remove-file"
				@click="$emit( 'remove-row' )"
			/>

			<div
				v-else-if="upload_complete"
				class="upload-modal__completed-row-icon"
			/>
		</td>
	</tr>
	<tr v-if="errors">
		<td colspan="5">
			<div class="upload-modal__errors">
				<div
					v-for="error, error_index in errors"
					:key="`error-${ error_index }`"
					class="form__error"
				>
					<p>
						{{ error }}
					</p>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { DragDrop } from '@uppy/vue';

import moment from 'moment';
import { useDataStore } from '../../stores/data';
import { storeToRefs } from 'pinia';
import { validateRevisionNumber } from '../../../helpers';

export default {
	name: 'ImageUploadComponent',
	components: {
		DragDrop
	},
	inject: [
		'tl_key',
		'tl_explore_thumbnail_template_id'
	],
	props: {
		project: { required: true, type: Object },
		asset: { required: false, type: Object, default: null },
		stage: { required: false, type: Object, default: null },
		canRemove: { required: true, type: Boolean },
		assetsList: { required: true, type: Array },
		staffList: { required: true, type: Array },
		uploadId: { required: true, type: String },
		entries: { required: true, type: Array },
		artistId: { required: false, type: String, default: null }
	},
	emits: [ 'upload-completed', 'upload-failed', 'file-added', 'files-removed' ],
	setup() {
		const data_store = useDataStore();
		const { user_profile } = storeToRefs(data_store);
		const { createExploreOption } = data_store;
        return {
            user_profile,
            createExploreOption
        };
	},
	data() {
		return {
			hover: false,
			uppy: null,
			entry: {
				asset_id: '',
				tour_id: '',
				revision_number: '',
				artist_id: ''
			},
			explore_id: null,
			errors: '',
			uppy_config: {
				height: '100%',
				width: '100%',
				locale: {
					strings: {
						poweredBy: 'The Boundary Live',
						dropHereOr: 'Choose an image',
					}
				},
				onDragOver() {
					this.hover = true;
				},
				onDragLeave() {
					this.hover = false;
				}
			},
			files: {},
			uppy_errors: {},
			expiry_time: null,
			signature: null,
			upload_complete: false
		};
	},
	computed: {
		project_staff_list() {
			return this.staffList.filter( user => this.userIsOnProject( user ) );
		},
		uppy_params() {
			return {
				auth: {
					key: this.tl_key,
					expires: this.expiry_time
				},
				template_id: this.tl_explore_thumbnail_template_id
			};
		},
		uppy_files() {
			if ( !this.uppy ) {
				return [];
			}
			return this.uppy.getFiles();
		},
		assembly_options() {
			return ( file ) => {
				return {
					fields: {
						filename: this.files[file.id].filename,
						entry_id: parseInt( this.explore_id )
					},
					waitForEncoding: false,
					params: this.uppy_params,
					signature: `sha1:${this.signature}`
				};
			};
		}
	},
	mounted() {
		this.getSigningString();

		if ( this.asset ) {
			this.entry.asset_id = this.asset.id;
		}
		if ( this.stage ) {
			this.entry.revision_number = this.stage;
		}

		if ( this.staffList.find( user => user.value === this.user_profile.id ) ) {
			this.entry.artist_id = this.user_profile.id;
		} else if ( this.staffList.length ) {
			this.entry.artist_id = this.staffList[0].id;
		}
	},
	watch: {
		artistId( value ) {
			if ( value === null ) {
				return;
			}
			this.entry.artist_id = value;
		}
	},
	methods: {
		userIsOnProject( user ) {
            return this.project.project_artists.map(artist => artist.id).includes(user.value);
        },
		formatTourId() {
			this.entry.tour_id = this.entry.tour_id.split( '/' ).at( -1 );
		},
		async getSigningString() {
			this.expiry_time = moment.utc().add( 2, 'h' ).format( 'YYYY/MM/DD HH:mm:ss+00:00' );
			try {
				const response = await this.$axios.post( '/upload/sign', {
					'params': JSON.stringify( this.uppy_params )
				} );
				this.signature = response.data;
				this.instantiateUppy();
			} catch ( error ) {
				// console.log( error );
			}
		},
		instantiateUppy() {
			this.uppy = new Uppy( {
				onBeforeFileAdded: this.addFile,
				restrictions: {
					allowedFileTypes: [ '.jpg', '.jpeg' ],
					minNumberOfFiles: 1,
					maxNumberOfFiles: 1,
				}
			} );
			this.uppy.use( ThumbnailGenerator, {
				thumbnailWidth: 120,
				waitForThumbnailsBeforeUpload: true,
			} );
			this.uppy.use( Transloadit, {
				getAssemblyOptions: this.assembly_options
			} );
			this.uppy.on( 'thumbnail:generated', ( file, preview ) => {
				this.files[file.id].preview = preview;
			} );
			this.uppy.on( 'file-added', () => {
				this.$emit( 'files-added', this.files );
			} );
			this.uppy.on( 'file-removed', ( file ) => {
				if ( file.id in this.files ) {
					delete this.files[file.id];
					this.$emit( 'files-removed', this.files );
				}
			} );
		},
		addFile( new_file ) {
			this.files[new_file.id] = {
				preview: new_file.preview,
				filename: new_file.name,
				extension: new_file.extension
			};
		},
		removeFile( file_id ) {
			this.uppy.removeFile( file_id );
		},
		getError(field_handle) {
            switch (field_handle) {
                case 'tour_id':
                    return 'Please enter a tour ID';
                case 'asset_id':
                    return 'Please select an asset';
                case 'revision_number':
                    return 'Please enter a valid stage (e.g. A1, B12, etc.)';
                case 'artist_id':
                    return 'Please enter an artist';
            }
        },
		checkValidity() {
            let invalid = false;
			this.errors = {};
			const empty_fields = Object.keys(this.entry).map(field => !this.entry[field] ? field : null).filter(field => field);
			if (empty_fields.length) {
				invalid = true;
				empty_fields.forEach(field => {
					if (!this.errors) {
						this.errors = {};
					}
					this.errors[field] = this.getError(field);
				});
			}
			if (!validateRevisionNumber(this.entry['revision_number'])) {
				invalid = true;
				this.errors['revision_number'] = this.getError('revision_number');
			}
			if ( !Object.keys(this.files).length ) {
				invalid = true;
				this.errors['thumbnail'] = 'Please add a thumbnail image';
			}
            if (invalid) {
                this.$emit('validity-checked', false);
				return;
            }
            this.$emit('validity-checked', true)
        },
		async submitUpload() {
			this.errors = {};
			if ( !this.explore_id ) {
				try {
					const explore_option = await this.createExploreOption( {
						tour_id: this.entry.tour_id,
						revision_number: this.entry.revision_number,
						asset_id: this.asset ? parseInt( this.asset.id ) : parseInt( this.entry.asset_id ),
						artist_id: parseInt( this.entry.artist_id ),
						upload_id: this.uploadId
					} );

					if ( explore_option ) {
						this.explore_id = explore_option.id;
					}
				} catch(error) {
					this.$emit( 'upload-failed' );
					this.errors['entry'] = error;
					return;
				}
			}

			if ( !this.explore_id ) {
				this.$emit( 'upload-failed' );
				this.errors['entry'] = 'Something went wrong while creating the entry, please try again.';
				return;
			}

			this.uppy.upload()
				.then( ( result ) => {
					if ( result.failed.length > 0 ) {
						this.$emit( 'upload-failed' );
						this.errors['thumbnail'] = 'Something went wrong while uploading the thumbnail, please try again.';
						return;
					}
					this.upload_complete = true;
					this.$emit( 'upload-completed' );
				} )
				.catch( async() => {
					this.$emit( 'upload-failed' );
					this.errors['thumbnail'] = 'Something went wrong while uploading the thumbnail, please try again.';
				} );
		},
		clearUppy() {
			this.uppy.cancelAll();
			this.files = {};
		}
	}
};
</script>