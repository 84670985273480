
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading_project" />

			<div v-else>
				<div class="flex flex--gap-large flex--align-center mb-32">
					<h1 class="project-title">
						{{ project.title }}
					</h1>
					<button
						class="favourite-project"
						:class="[
							{ 'favourite-project--selected': user_has_favourited_project },
							{ 'favourite-project--toggled': user_has_toggled_favourite },
							{ 'favourite-project--loading': loading_favourite }
						]"
						@click="toggleFavouriteCurrentProject"
					/>
					<button
						v-if="!user_is_in_directory"
						class="button"
						:class="{ 'button--disabled button--loading': setting_subscribed }"
						@click="toggleSubscribed"
					>
						{{ subscribed_label }}
					</button>

					<div
						v-if="project.project_status !== 'completed' && !user_is_client"
						class="flex flex--justify-end flex--align-start flex--grow"
					>
						<button
							class="button"
							@click="startUploadFlow"
						>
							Add media
						</button>
					</div>
				</div>

				<div class="flex flex--wrap flex--gap-large flex--justify-space-between mb-40">
					<TabNavigationComponent
						:tabs="tabs"
						:active-tab="active_tab"
						:no-margin="true"
						@set-tab="setRoute"
					/>

					<TabNavigationComponent
						:tabs="view_modes"
						:active-tab="active_view_mode"
						:no-margin="true"
						@set-tab="setViewMode"
					/>
				</div>

				<Transition name="fade">
					<PageLoaderComponent v-if="loading_tab" />

					<ProjectOverviewComponent
						v-else-if="active_tab === 'overview' && project"
						:project="project"
						:submissions="submissions"
						:view-mode="active_view_mode"
						@set-route="setRoute"
						@refresh-project="refreshProject"
					/>

					<SubmissionListComponent
						v-else-if="active_tab === 'submissions' && project && submissions && submissions.length"
						:submissions="submissions"
						:project="project"
					/>

					<ProjectAssetsComponent
						v-else-if="active_tab === 'grid' && project"
						:project="project"
						@refresh-project="refreshProject"
					/>

					<ProjectDiscussionsComponent
						v-else-if="active_tab === 'discussion' && project"
						:project="project"
					/>

					<ProjectDirectoryComponent
						v-else-if="active_tab === 'directory' && project"
						:project="project"
						@update-directory="updateDirectory"
					/>

					<ProjectTagsComponent
						v-else-if="active_tab === 'tags' && project"
						:project="project"
					/>

					<div v-else-if="active_tab === 'notifications'">
						<div class="flex flex--gap-large flex--align-center mb-32">
							<p>
								View:
							</p>
							<LightswitchComponent
								label="All"
								off-label="All"
								:checked="all_filters_active"
								:disabled="loading || loading_notifications"
								@change="toggleAllFilters"
							/>
							<LightswitchComponent
								v-for="filter, index in filters"
								:key="`filter-${index}`"
								:label="filter.label"
								:off-label="filter.label"
								:checked="filter.active"
								:disabled="loading || loading_notifications"
								@change="toggleFilter( filter )"
							/>
						</div>
						<NotificationsComponent
							:notifications="notifications"
							:pending-count="notifications_pagination.pending_count"
							:loading="loading_notifications"
							@mark-as-read="markNotificationAsRead"
							@mark-all-as-read="markAllNotificationsAsRead"
						/>

						<div
							v-show="notifications_pagination.total_count > notifications.length"
							ref="load_more_trigger"
							class="load-more-trigger"
						/>
					</div>

					<p v-else-if="!loading">
						No content.
					</p>
				</Transition>
			</div>
		</Transition>
	</div>

	<UploadModalComponent
		v-if="project"
		:show="show_upload_modal"
		:project="project"
		:asset="asset_to_upload_to"
		@close-modal="closeUploadModal"
	/>
</template>

<script setup>
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import TabNavigationComponent from '../components/TabNavigationComponent.vue';
import UploadModalComponent from '../components/uploads/UploadModalComponent.vue';
import ProjectAssetsComponent from '../components/ProjectAssetsComponent.vue';
import ProjectDiscussionsComponent from '../components/ProjectDiscussionsComponent.vue';
import ProjectDirectoryComponent from '../components/ProjectDirectoryComponent.vue';
import ProjectOverviewComponent from '../components/ProjectOverviewComponent.vue';
import ProjectTagsComponent from '../components/ProjectTagsComponent.vue';
import NotificationsComponent from '../components/NotificationsComponent.vue';
import SubmissionListComponent from '../components/SubmissionListComponent.vue';
import LightswitchComponent from '../components/LightswitchComponent.vue';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useElementVisibility } from '@vueuse/core';
import { updatePageTitle } from '../../helpers';

import { USER_GROUP_STAFF, USER_GROUP_CLIENT } from '../constants';

const data_store = useDataStore();
const {
	getProjectById,
	getSubmissionsByProjectId,
	getUserProfile,
	getNotifications,
	getPendingNotificationsCount,
	getUserRelatedNotificationsCount,
	markNotificationsAsRead,
	toggleFavouriteProject,
	setSubscribedProjects,
	setAlertMessage,
	setBreadcrumbs,
	isUserStaffSuperAdmin,
	user_group
} = data_store;
const { user_profile } = storeToRefs( data_store );

const active_tab = ref( 'overview' );
const loading_project = ref( true );
const loading_tab = ref( true );
const loading_favourite = ref( false );
const project = ref( null );
const submissions = ref( null );
const notifications = ref( [] );
const notifications_pagination = reactive( {
	page: 1,
	per_page: 10,
	unfiltered_pending_count: 0,
	pending_count: 0,
	total_count: 0
} );
const notifications_filters = ref( [
	{ slug: 'discussions', label: 'Discussions', active: true },
	{ slug: 'uploads', label: 'Asset uploads', active: true },
	{ slug: 'client_approvals', label: 'Approvals', active: true },
	{ slug: 'submissions', label: 'Submissions', active: true }
] );
const loading_notifications = ref( false );
const asset_to_upload_to = ref( null );
const show_upload_modal = ref( false );
const user_has_toggled_favourite = ref( false );
const user_is_staff_super_admin = ref( false );
const setting_subscribed = ref( false );
const unfiltered_tabs = ref( [
	{ slug: 'overview', label: 'Overview' },
	{ slug: 'submissions', label: 'Submissions' },
	{ slug: 'grid', label: 'Grid' },
	{ slug: 'tags', label: 'Tags' },
	{ slug: 'discussion', label: 'Discussion' },
	{ slug: 'directory', label: 'Directory' },
	{
		slug: 'notifications',
		label: 'Notifications',
		count: notifications_pagination.unfiltered_pending_count
	}
] );
const active_view_mode = ref( 'staff' );
const view_modes= ref( [
	{ slug: 'staff', label: 'Staff' },
	{ slug: 'client', label: 'Client' },
] );

const router = useRouter();
const route = useRoute();

// Tabs
const tabs = computed( () => {
	if ( user_is_staff_super_admin.value ) {
		return unfiltered_tabs.value;
	}
	return unfiltered_tabs.value.filter( tab => tab.slug !== 'tags' );
});

async function setTab( slug ) {
	loading_tab.value = true;
	active_tab.value = slug;

	if ( slug === 'notifications' ) {
		await getProjectNotifications( {
			page: 1,
			per_page: notifications_pagination.per_page
		} );
	}

	const breadcrumbs = [
		{
			label: 'Projects',
			route: { name: 'projects' },
			icon: 'briefcase'
		},
		{
			label: project.value.title,
		}
	];

	if ( slug !== 'overview' ) {
		breadcrumbs.push( { label: unfiltered_tabs.value.find( tab => tab.slug === slug ).label } );
	}

	setBreadcrumbs( breadcrumbs );
	loading_tab.value = false;
}

function setRoute( slug ) {
	router.push( {
		name: 'projects__project',
		params: { project_id: route.params.project_id },
		query: { tab: slug }
	} );
}

// View Mode
function setViewMode( slug ) {
	active_view_mode.value = slug;
}

// General
const user_is_staff = computed( () => {
	return user_group === USER_GROUP_STAFF;
});

const user_is_client = computed( () => {
	return user_group === USER_GROUP_CLIENT;
});

const user_has_favourited_project = computed( () => {
	return user_profile.value.favourite_projects.find( favourite_project => parseInt( favourite_project.id ) === parseInt( route.params.project_id ) );
});

const user_is_subscribed = computed( () => {
	return user_profile.value.subscribed_projects.find( subscribed_project => parseInt( subscribed_project.id ) === parseInt( project.value.id ) );
});

const subscribed_label = computed( () => {
	if ( user_is_subscribed.value ) {
		return 'Remove from My Projects';
	}
	return 'Add to My Projects';
});

watch( () => route.query.tab, ( new_tab, old_tab ) => {
		setAlertMessage( null );
		if ( !new_tab ) {
			return;
		}
		if ( new_tab === old_tab ) {
			return;
		}
		setTab( new_tab );
	}
);

async function getProject() {
	try {
		const response = await getProjectById( route.params.project_id );
		if ( response.data ) {
			project.value = response.data.entry;
		}
		const unfiltered_notification_count = await getPendingNotificationsCount( {
			project_ids: [ parseInt( route.params.project_id ) ]
		} );
		notifications_pagination.unfiltered_pending_count = unfiltered_notification_count;
	} catch ( error ) {
		router.push( { name: 'projects' } );
	}
}

async function refreshProject() {
	loading_tab.value = true;
	try {
		const response = await getProjectById( route.params.project_id );
		if ( response.data ) {
			project.value = response.data.entry;
		}
	} catch ( error ) {
		router.push( { name: 'projects' } );
	}
	loading_tab.value = false;
}

function startUploadFlow() {
	if ( route.query && route.query.virtual_tour ) {
		asset_to_upload_to.value = project.value.project_assets.find( asset => asset.id === route.query.virtual_tour );
	}
	show_upload_modal.value = true;
}

function closeUploadModal() {
	show_upload_modal.value = false;
	asset_to_upload_to.value = null;
}

async function toggleFavouriteCurrentProject() {
	user_has_toggled_favourite.value = false;
	loading_favourite.value = true;
	try {
		const response = await toggleFavouriteProject( parseInt( project.value.id ) );
		if ( response.data ) {
			await getUserProfile();
		}
	} catch ( error ) {
		// console.log( error );
	}
	user_has_toggled_favourite.value = true;
	loading_favourite.value = false;
}

async function toggleSubscribed() {
	let project_ids = [
		...user_profile.value.subscribed_projects.map( project => +project.id ),
		+project.value.id
	];
	if ( user_is_subscribed.value ) {
		project_ids = project_ids.filter( id => +id !== +project.value.id );
	}

	setting_subscribed.value = true;
	await setSubscribedProjects( project_ids );
	setting_subscribed.value = false;

	getUserRelatedNotificationsCount();
}

// Submissions
async function getSubmissions() {
	try {
		const response = await getSubmissionsByProjectId( route.params.project_id );
		if ( response.data ) {
			submissions.value = response.data.entries;
		}
	} catch ( error ) {
		// console.log( error );
	}
}

// Directory
const user_is_in_directory = computed( () => {
	const all_directory_users = [
		...project.value.business_development,
		...project.value.account_director,
		...project.value.project_manager,
		...project.value.lead_artist,
		...project.value.project_artists,
		...project.value.clients
	];
	return all_directory_users.find( user => +user.id === +user_profile.value.id );
});

function updateDirectory( directory ) {
	for ( const [ key, value ] of Object.entries( directory ) ) {
		if ( project.value[ key ] ) {
			project.value[ key ] = value;
		}
	}
}

// Notifications
const load_more_trigger = ref( null );
const trigger_is_visible = useElementVisibility( load_more_trigger );

const filters = computed( () => {
	if ( user_is_staff.value ) {
		return notifications_filters.value.filter( filter => filter.slug !== 'submissions' );
	}
	return notifications_filters.value.filter( filter => filter.slug !== 'uploads' && filter.slug !== 'client_approvals' );
});

const all_filters_active = computed( () => {
	return filters.value.every( filter => filter.active === true );
});

watch( trigger_is_visible, async ( new_value, old_value ) => {
		if ( new_value && !old_value && !loading_notifications.value ) {
			await getProjectNotifications( {
				page: notifications_pagination.page + 1,
				per_page: notifications_pagination.per_page
			} );
		}
	}
);

async function getProjectNotifications( { page, per_page } ) {
	loading_notifications.value = true;
	try {
		const response = await getNotifications( {
			project_ids: [ parseInt( route.params.project_id ) ],
			types: getFormattedNotificationTypes(),
			page,
			per_page
		} );
		if ( response.data && response.data.notifications ) {
			if ( page > 1 ) {
				notifications.value.push( ...response.data.notifications );
			} else {
				notifications.value = response.data.notifications;
			}
			notifications_pagination.page = page;
			notifications_pagination.per_page = per_page;
			notifications_pagination.pending_count = response.data.pendingNotificationsTotal;
			notifications_pagination.total_count = response.data.notificationsTotal;
		}
	} catch ( error ) {
		// console.log( error );
	}
	loading_notifications.value = false;
}

function markNotificationAsRead( notification_id ) {
	const notification = notifications.value.find( notification => notification.id === notification_id );
	if ( !notification ) {
		return;
	}
	notifications.value[ notifications.value.indexOf( notification ) ].read = true;
	notifications_pagination.pending_count = notifications_pagination.pending_count - 1;
}

async function markAllNotificationsAsRead() {
	const response = await markNotificationsAsRead( {
		project_ids: [ parseInt( route.params.project_id ) ]
	} );
	if ( !response || !response.readNotifications ) {
		return;
	}
	notifications.value = [ ...notifications.value ].map( notification => {
		return { ...notification, read: true };
	} );
	notifications_pagination.pending_count = 0;
	await getUserRelatedNotificationsCount();
}

function getFormattedNotificationTypes() {
	const formatted_types = [];
	const types = {
		'discussions': ['OptionCommentPosted', 'DiscussionCommentPosted', 'DiscussionCreated'],
		'client_approvals': ['OptionClientApproved', 'RevisionClientApproved'],
		'uploads': ['UploadAdded'],
		'submissions': ['SubmissionCreated']
	};
	filters.value.forEach( filter => {
		if ( filter.active === true ) {
			formatted_types.push( ...types[ filter.slug ] );
		}
	});
	return formatted_types;
}

async function toggleAllFilters() {
	if ( all_filters_active.value ) {
		filters.value.forEach( filter => {
			if ( filter.active ) {
				setProjectNotificationFilter( filter.slug, false );
			}
		} );
	} else {
		filters.value.forEach( filter => {
			if ( !filter.active ) {
				setProjectNotificationFilter( filter.slug, true );
			}
		} );
	}

	notifications.value = [];
	await getProjectNotifications( {
		page: 1,
		per_page: notifications_pagination.per_page
	} );
}

async function toggleFilter( filter ) {
	setProjectNotificationFilter( filter.slug, !filter.active );

	notifications.value = [];
	await getProjectNotifications( {
		page: 1,
		per_page: notifications_pagination.per_page
	} );
}

function setProjectNotificationFilter( slug, active ) {
	const index = notifications_filters.value.findIndex( filter => filter.slug === slug );
	notifications_filters.value[ index ].active = active;
}

// Mounted
onMounted( async () => {
	await getProject();
	updatePageTitle( project.value.title );
	user_is_staff_super_admin.value = await isUserStaffSuperAdmin();

	await getSubmissions();

	loading_project.value = false;

	const breadcrumbs = [
		{
			label: 'Projects',
			route: {
				name: 'projects'
			},
			icon: 'briefcase'
		},
		{
			label: project.value.title,
		}
	];

	if ( route.query && route.query.tab ) {
		active_tab.value = route.query.tab;

		if ( route.query.tab !== 'overview' ) {
			breadcrumbs.push( { label: tabs.value.find( tab => tab.slug === route.query.tab ).label } );
		}

		if ( route.query.tab === 'notifications' ) {
			await getProjectNotifications( {
				page: 1,
				per_page: notifications_pagination.per_page
			} );
		}
	}

	setBreadcrumbs( breadcrumbs );

	loading_tab.value = false;
} );
</script>
