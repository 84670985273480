<template>
	<ul
		class="tab-navigation"
		:class="[
			theme_class ? theme_class : '',
			{ 'tab-navigation--disabled': disabled },
			{ 'tab-navigation--reduced-margin': reducedMargin },
			{ 'tab-navigation--no-margin': noMargin },
			{ 'tab-navigation--slim': slim },
		]"
	>
		<li
			v-for="tab, index in tabs"
			:key="'tab-' + index"
			class="tab-navigation__tab"
			:class="{ 'tab-navigation__tab--active': tab.slug === activeTab }"
		>
			<button @click="$emit( 'set-tab', tab.slug )">
				{{ tab.label }}
				<span
					v-if="tab.count"
					class="tab-navigation__tab-count"
				>
					{{ tab.count }}
				</span>
			</button>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		tabs: { required: true, type: Array },
		activeTab: { required: true, type: String },
		disabled: { required: false, type: Boolean, default: false },
		reducedMargin: { required: false, type: Boolean, default: false },
		noMargin: { required: false, type: Boolean, default: false },
		theme: { required: false, default: null },
		slim: { required: false, default: false },
	},
	emits: [ 'set-tab' ],
	computed: {
		theme_class() {
			if ( this.theme ) {
				return `tab-navigation--${this.theme}`;
			}
			return null;
		}
	}
};
</script>
