<template>
	<Transition name="fade">
		<PageLoaderComponent v-if="loading" />

		<div
			v-else
			class="mb-32"
		>
			<router-link
				class="back-to mb-32"
				:to="{
					name: 'projects__project',
					params: {
						project_id: this.$route.params.project_id
					},
					query: {
						tab: 'grid'
					}
				}"
			>
				Back to main grid
			</router-link>

			<div class="flex flex--wrap flex--justify-space-between mb-32">
				<div class="a7-12 f6-6">
					<h3 class="form__label">
						Jump to:
					</h3>
					<div class="flex flex--column-gap-large flex--row-gap-medium flex--wrap">
						<button
							key="jump-to-virtual-tour"
							class="p--small a a--underline"
							@click="scrollTo( 'virtual-tour' )"
						>
							Virtual Tour Asset
						</button>
						<button
							v-if="animations.length"
							key="jump-to-animations"
							class="p--small a a--underline"
							@click="scrollTo( 'animations' )"
						>
							Animation
						</button>
						<button
							v-if="stills.length"
							key="jump-to-stills"
							class="p--small a a--underline"
							@click="scrollTo( 'stills' )"
						>
							Stills
						</button>
					</div>
				</div>

				<div class="a5-12 f6-6 flex flex--gap-medium flex--justify-end flex--align-center">
					<button
						class="p--small a a--underline"
						@click="toggleAllChecked"
					>
						{{ everything_checked ? 'Deselect all' : 'Select all' }}
					</button>
					<button
						class="button button--fill"
						:class="{ 'button--loading': loading_download }"
						:disabled="nothing_checked"
						@click="downloadSelectedLatestOptions"
					>
						Download selected
					</button>
				</div>
			</div>

			<h2
				id="virtual-tour"
				class="h4"
			>
				Virtual Tour
			</h2>

			<div class="grid grid--xs flex flex--wrap mb-32">
				<div class="grid__item a2-12 mb-32">
					<router-link
						:to="{
							name: 'projects__project__asset',
							params: {
								project_id: $route.params.project_id,
								asset_id: asset.id
							}
						}"
						class="image-grid-card"
						:class="{ 'image-grid-card--greyed-out': asset.asset_status && ['paused', 'cancelled'].includes( asset.asset_status ) }"
					>
						<div class="image-grid-card__image-wrapper">
							<img
								v-if="asset.featured_image_small.url"
								:src="asset.featured_image_small.url"
								:alt="asset.featured_image_small.alt_text"
								class="image-grid-card__image"
							>

							<div
								v-else
								class="image-grid-card__image image-grid-card__image--default"
							/>
						</div>

						<div
							class="image-grid-card__info"
							:class="{ 'image-grid-card__info--with-revision': asset.last_revision }"
						>
							<p class="image-grid-card__title">
								{{ asset.title }}
							</p>
							<div class="image-grid-card__details-container">
								<div
									v-if="asset.last_revision"
									class="image-grid-card__details"
								>
									<div class="image-grid-card__details-icons">
										<span
											:class="[
												'image-grid-card__stage',
												getRevisionPipClass( asset.last_revision )
											]"
										>
											{{ asset.last_revision.revision_number }}
										</span>
										<span
											v-if="unreadCommentCount"
											class="image-grid-card__comments"
										>
											<span class="image-grid-card__comment-count">{{ unreadCommentCount }}</span>
										</span>
									</div>
									<span
										class="image-grid-card__asset-status"
										v-if="asset.asset_status && ['paused', 'cancelled'].includes( asset.asset_status )"
									>
										{{ asset.asset_status === 'paused' ? '[Paused]' : '[Cancelled]' }}
									</span>
								</div>
							</div>
						</div>
						<button
							v-if="asset.featured_image_small.url"
							class="image-grid-card__fullscreen"
							aria-label="View image fullscreen"
							@click.prevent="openAssetGallery"
						/>
						<button
							v-if="asset.media_type[0].base_type === 'image' && asset.featured_image_small.url"
							class="image-grid-card__checkbox"
							:class="{ 'image-grid-card__checkbox--checked': checked_assets.includes( asset.id ) }"
							:aria-label="checked_assets.includes( asset.id ) ? `Select asset's images for download` : `Deselect asset's images for download`"
							@click.prevent="asset_checked = !asset_checked"
						/>
					</router-link>
				</div>
			</div>

			<template v-if="animations.length > 0">
				<h2
					id="animations"
					class="h4"
				>
					Animation from Virtual Tour
				</h2>

				<div class="grid grid--xs flex flex--wrap mb-32">
					<div
						v-for="option in animations"
						:key="'animation-' + option.id"
						class="grid__item a2-12 mb-32"
					>
						<div class="image-grid-card image-grid-card--shadowless">
							<div class="image-grid-card__image-wrapper">
								<img
									v-if="option.thumbnail_url"
									:src="option.thumbnail_url"
									:alt="option.title"
									class="image-grid-card__image"
								>

								<div
									v-else
									class="image-grid-card__image image-grid-card__image--default"
								/>
							</div>

							<div class="image-grid-card__info">
								<p class="image-grid-card__title">
									{{ option.title }}
								</p>
							</div>
							<button
								v-if="option.video_url"
								class="image-grid-card__fullscreen"
								aria-label="View image fullscreen"
								@click.prevent="openAnimationsGallery( option.id )"
							/>
						</div>
					</div>
				</div>
			</template>

			<template v-if="stills.length > 0">
				<h2
					id="stills"
					class="h4"
				>
					Stills from Virtual Tour
				</h2>

				<div class="grid grid--xs flex flex--wrap mb-32">
					<div
						v-for="option in stills"
						:key="'animation-' + option.id"
						class="grid__item a2-12 mb-32"
					>
						<div class="image-grid-card image-grid-card--shadowless">
							<div class="image-grid-card__image-wrapper">
								<img
									v-if="option.image_small_url"
									:src="option.image_small_url"
									:alt="option.title"
									class="image-grid-card__image"
								>

								<div
									v-else
									class="image-grid-card__image image-grid-card__image--default"
								/>
							</div>

							<div class="image-grid-card__info">
								<p class="image-grid-card__title">
									{{ option.title }}
								</p>
							</div>
							<button
								v-if="option.image_original_url || option.image_large_url"
								class="image-grid-card__fullscreen"
								aria-label="View image fullscreen"
								@click.prevent="openStillsGallery( option.id )"
							/>
							<button
								v-if="option.image_original_url || option.image_large_url"
								class="image-grid-card__checkbox"
								:class="{ 'image-grid-card__checkbox--checked': checked_options.includes( option.id ) }"
								:aria-label="checked_options.includes( option.id ) ? `Select asset's images for download` : `Deselect asset's images for download`"
								@click.prevent="toggleOptionCheck( option.id )"
							/>
						</div>
					</div>
				</div>
			</template>
		</div>
	</Transition>

	<Teleport to="#modal">
		<Transition name="fade">
			<GalleryModalComponent
				v-if="gallery_options && gallery_options.length && show_gallery_modal"
				:options="gallery_options"
				@close-modal="closeGalleryModal"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import GalleryModalComponent from './modals/GalleryModalComponent.vue';
import DeleteAssetModalComponent from './modals/DeleteAssetModalComponent.vue';
import AssetStatusModalComponent from './modals/AssetStatusModalComponent.vue';
import BasicNewAssetModalComponent from './modals/BasicNewAssetModalComponent.vue';
import NewAssetModalComponent from './modals/NewAssetModalComponent.vue';
import PageLoaderComponent from './PageLoaderComponent.vue';

import { getAssetThumbnailOption } from '../../helpers';
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import VueScrollTo from 'vue-scrollto';

export default {
	components: {
		GalleryModalComponent,
		DeleteAssetModalComponent,
		AssetStatusModalComponent,
		BasicNewAssetModalComponent,
		NewAssetModalComponent,
		PageLoaderComponent
	},
	props: {
		project: {
			required: true,
			type: Object
		},
		asset: {
			required: true,
			type: Object
		},
		unreadCommentCount: {
			required: true,
			type: Number
		}
	},
	setup() {
		const data_store = useDataStore();
		const {
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			createArchive,
			deleteAsset,
			setAlertMessage,
			getUnreadCommentCountsByProjectId
		} = data_store;
		return {
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			createArchive,
			deleteAsset,
			setAlertMessage,
			getUnreadCommentCountsByProjectId
		};
	},
	data() {
		return {
			loading: true,
			user_is_staff_admin: false,
			user_is_staff_super_admin: false,
			checked_options: [],
			gallery_options: null,
			selected_media_type_id: null,
			show_gallery_modal: false,
			show_basic_new_asset_modal: false,
			show_new_asset_modal: false,
			show_asset_status_modal: false,
			show_delete_asset_modal: false,
			loading_download: false,
			grid_key: 1,
			visible_action_tooltip: null,
			asset_changing_status: null,
			asset_to_delete: null,
			setting_thumbnail: null
		};
	},
	computed: {
		stills() {
			return this.asset.virtual_tour_stills ?? [];
		},
		animations() {
			return this.asset.virtual_tour_animations ?? [];
		},
		nothing_checked() {
			return !this.checked_options.length;
		},
		everything_checked() {
			return this.checked_options.length === this.stills.length;
		}
	},
	async mounted() {
		this.user_is_staff_admin = await this.isUserStaffAdmin();
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();

		this.loading = false;
	},
	methods: {
		toggleOptionCheck( option_id ) {
			if ( this.checked_options.includes( option_id ) ) {
				this.checked_options.splice( this.checked_options.findIndex( id => id === option_id ), 1 );
				return;
			}
			this.checked_options.push( option_id );
		},
		toggleAllChecked() {
			if ( this.everything_checked ) {
				this.checked_options = [];
				return;
			}
			this.checked_options = this.stills.map( option => option.id );
		},
		async downloadSelectedLatestOptions() {
			this.loading_download = true;
			this.setAlertMessage( null );

			const option_ids = this.checked_options.map( option_id => parseInt( option_id ) );

			if ( this.asset_checked ) {
				const revisions_sorted_by_revision_number = this.asset.asset_revisions.sort( ( a, b ) => {
					if ( a.revision_number[0] < b.revision_number[0] ) {
						return -1;
					}
					if ( a.revision_number[0] > b.revision_number[0] ) {
						return 1;
					}
					return parseInt( a.revision_number.slice( 1 ) ) - parseInt( b.revision_number.slice( 1 ) );
				} );

				option_ids.push( ...revisions_sorted_by_revision_number.at( -1 ).revision_options.map( option => parseInt( option.id ) ) );
			}

			try {
				const response = await this.createArchive( {
					option_ids: option_ids,
					project_id: parseInt( this.$route.params.project_id )
				} );

				if ( response && response.data ) {
					this.setAlertMessage( "Your download is being prepared, we will email you a link when it's ready" ); // eslint-disable-line quotes
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_download = false;
		},
		getRevisionPipClass( revision ) {
			const has_unapproved_options = revision.revision_options.find( option => option.staff_approval_user.length < 1 );

			if ( has_unapproved_options ) {
				return 'image-grid-card__stage--unapproved';
			}

			const first_char = revision.revision_number[0];

			switch ( first_char ) {
				case 'A':
					return 'image-grid-card__stage--stage-a';
				case 'B':
					return 'image-grid-card__stage--stage-b';
				case 'C':
					return 'image-grid-card__stage--stage-c';
			}
		},
		openAssetGallery() {
			this.gallery_options = [ this.asset ]
				.filter( asset => asset.featured_image_large.url || asset.featured_video_url )
				.map( ( asset ) => {
					return {
						title: asset.title,
						image_url: asset.featured_image_large ? asset.featured_image_large.url : null,
						video_url: asset.featured_video_url ?? null,
						selected: true
					};
				} )
			;
			this.show_gallery_modal = true;
		},
		openAnimationsGallery( option_id ) {
			this.gallery_options = [ ...this.animations ]
				.filter( option => option.video_url )
				.map( ( option ) => {
					return {
						title: option.title,
						image_url: null,
						video_url: option.video_url ?? null,
						selected: option.id === option_id
					};
				} )
			;
			this.show_gallery_modal = true;
		},
		openStillsGallery( option_id ) {
			this.gallery_options = [ ...this.stills ]
				.filter( option => option.image_large_url )
				.map( ( option ) => {
					return {
						title: option.title,
						image_url: option.image_large_url ?? null,
						video_url: null,
						selected: option.id === option_id
					};
				} )
			;
			this.show_gallery_modal = true;
		},
		closeGalleryModal() {
			this.show_gallery_modal = false;
			this.gallery_options = null;
		},
		// handleNewAssetClick( media_type_name, media_type_id ) {
		// 	if ( ['Film', 'Virtual Tour'].includes( media_type_name ) ) {
		// 		this.openBasicNewAssetModal( media_type_id );
		// 		return;
		// 	}
		// 	this.openNewAssetModal( media_type_id );
		// },
		// openBasicNewAssetModal( media_type_id ) {
		// 	this.selected_media_type_id = parseInt( media_type_id );
		// 	this.show_basic_new_asset_modal = true;
		// },
		// openNewAssetModal( media_type_id ) {
		// 	this.selected_media_type_id = parseInt( media_type_id );
		// 	this.show_new_asset_modal = true;
		// },
		// closeBasicNewAssetModal() {
		// 	this.selected_media_type_id = null;
		// 	this.show_basic_new_asset_modal = false;
		// },
		// closeNewAssetModal() {
		// 	this.selected_media_type_id = null;
		// 	this.show_new_asset_modal = false;
		// },
		// toggleActionTooltip( asset_id ) {
		// 	if ( this.visible_action_tooltip === asset_id ) {
		// 		this.visible_action_tooltip = null;
		// 		return;
		// 	}
		// 	this.visible_action_tooltip = asset_id;
		// },
		// assetIsThumbnail( asset_id ) {
		// 	if ( !this.project.project_thumbnail || !this.project.project_thumbnail.length ) {
		// 		return false;
		// 	}
		// 	return this.project.project_thumbnail[0].id === asset_id;
		// },
		// async toggleAssetAsThumbnail( asset_id ) {
		// 	if ( this.assetIsThumbnail( asset_id ) ) {
		// 		await this.unsetProjectThumbnail( asset_id );
		// 		return;
		// 	}
		// 	await this.setAssetAsThumbnail( asset_id );
		// },
		// async setAssetAsThumbnail( asset_id ) {
		// 	if ( this.setting_thumbnail ) {
		// 		return;
		// 	}
		// 	this.setAlertMessage( null );
		// 	this.setting_thumbnail = asset_id;
		// 	try {
		// 		const response = await this.setProjectThumbnail( {
		// 			project_id: this.project.id,
		// 			asset_id: parseInt( asset_id )
		// 		} );
		// 		if ( response && response.data && response.data.save_projects_default_Entry ) {
		// 			this.$emit('refresh-project');
		// 			this.setAlertMessage( "Project thumbnail updated!" ); // eslint-disable-line quotes
		// 		}
		// 	} catch( error ) {
		// 		this.setAlertMessage( "Something went wrong, please try again" );
		// 		// console.log( error );
		// 	}
		// 	this.setting_thumbnail = null;
		// },
		// async unsetProjectThumbnail( asset_id ) {
		// 	if ( this.setting_thumbnail ) {
		// 		return;
		// 	}
		// 	this.setAlertMessage( null );
		// 	this.setting_thumbnail = asset_id;
		// 	try {
		// 		const response = await this.setProjectThumbnail( {
		// 			project_id: this.project.id,
		// 			asset_id: null
		// 		} );
		// 		if ( response && response.data && response.data.save_projects_default_Entry ) {
		// 			this.$emit('refresh-project');
		// 			this.setAlertMessage( "Project thumbnail removed." ); // eslint-disable-line quotes
		// 		}
		// 	} catch( error ) {
		// 		this.setAlertMessage( "Something went wrong, please try again" );
		// 		// console.log( error );
		// 	}
		// 	this.setting_thumbnail = null;
		// },
		// openDeleteAssetModal( asset ) {
		// 	this.asset_to_delete = asset;
		// 	this.show_delete_asset_modal = true;
		// },
		// closeDeleteAssetModal() {
		// 	this.asset_to_delete = null;
		// 	this.visible_action_tooltip = null;
		// 	this.show_delete_asset_modal = false;
		// },
		// openAssetStatusModal( asset ) {
		// 	this.asset_changing_status = asset;
		// 	this.show_asset_status_modal = true;
		// },
		// closeAssetStatusModal() {
		// 	this.asset_changing_status = null;
		// 	this.visible_action_tooltip = null;
		// 	this.show_asset_status_modal = false;
		// },
		scrollTo( id ) {
			VueScrollTo.scrollTo( `#${ id }`, {
				container: '#page',
				duration: 500,
				lazy: false
			} );
		},
	}
};
</script>
