import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "inline-thumbnail-upload__file" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["disabled", "onClick"]
const _hoisted_4 = { class: "inline-thumbnail-upload__filename" }
const _hoisted_5 = { class: "form__icon form__icon--down" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 1,
  class: "form__icon form__icon--down"
}
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["value"]
const _hoisted_13 = {
  key: 0,
  value: ""
}
const _hoisted_14 = {
  key: 1,
  class: "upload-modal__completed-row-icon"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { colspan: "5" }
const _hoisted_17 = { class: "upload-modal__errors" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_drag_drop = _resolveComponent("drag-drop")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, [
        _createElementVNode("div", {
          class: _normalizeClass(["inline-thumbnail-upload", [
					{ 'inline-thumbnail-upload--hover': $data.hover },
					{ 'inline-thumbnail-upload--error': $data.errors && $data.errors.thumbnail }
				]])
        }, [
          ($data.uppy && !Object.keys($data.files).length)
            ? (_openBlock(), _createBlock(_component_drag_drop, {
                key: 0,
                uppy: $data.uppy,
                props: $data.uppy_config
              }, null, 8 /* PROPS */, ["uppy", "props"]))
            : _createCommentVNode("v-if", true),
          (Object.keys($data.files).length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($data.files, (file, file_id) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file_id,
                  class: "inline-thumbnail-upload__file-container"
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    (file.preview)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "inline-thumbnail-upload__thumbnail",
                          src: file.preview,
                          alt: file.filename
                        }, null, 8 /* PROPS */, _hoisted_2))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("button", {
                      class: _normalizeClass(["inline-thumbnail-upload__remove-file", { 'inline-thumbnail-upload__remove-file--disabled': $data.upload_complete }]),
                      disabled: $data.upload_complete,
                      onClick: $event => ($options.removeFile( file_id ))
                    }, null, 10 /* CLASS, PROPS */, _hoisted_3)
                  ]),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(file.filename), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */)
      ]),
      _createElementVNode("td", null, [
        ($props.asset || $data.explore_id)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString($props.assetsList.find( asset => asset.value === $data.entry.asset_id ).label), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["form__set form__set--no-margin", { 'form__set--error': $data.errors && $data.errors.asset_id }])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.entry.asset_id) = $event)),
                  class: "form__select form__select--stretch",
                  disabled: !!$data.explore_id
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.assetsList, (project_asset) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: `asset-${ project_asset.value }`,
                      value: project_asset.value
                    }, _toDisplayString(project_asset.label), 9 /* TEXT, PROPS */, _hoisted_7))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 8 /* PROPS */, _hoisted_6), [
                  [_vModelSelect, $data.entry.asset_id]
                ])
              ])
            ], 2 /* CLASS */))
      ]),
      _createElementVNode("td", null, [
        ($data.explore_id)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString($data.entry.tour_id), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["form__set form__set--prefix form__set--no-margin", { 'form__set--error': $data.errors && $data.errors.tour_id }])
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.entry.tour_id) = $event)),
                class: "form__input form__input--stretch",
                type: "text",
                disabled: !!$data.explore_id,
                placeholder: "e.g. cm3x8uz3e0001kb8jujco03r9",
                onBlur: _cache[2] || (_cache[2] = (...args) => ($options.formatTourId && $options.formatTourId(...args)))
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_8), [
                [_vModelText, $data.entry.tour_id]
              ])
            ], 2 /* CLASS */))
      ]),
      _createElementVNode("td", null, [
        ($props.stage || $data.explore_id)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString($data.entry.revision_number), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["form__set form__set--no-margin", { 'form__set--error': $data.errors && $data.errors.revision_number }])
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.entry.revision_number) = $event)),
                class: "form__input form__input--stretch",
                type: "text",
                disabled: !!$data.explore_id
              }, null, 8 /* PROPS */, _hoisted_9), [
                [_vModelText, $data.entry.revision_number]
              ])
            ], 2 /* CLASS */))
      ]),
      _createElementVNode("td", null, [
        ($data.explore_id)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString($props.staffList.find( user => user.value === $data.entry.artist_id ).label), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.entry.artist_id) = $event)),
                class: _normalizeClass(["form__select form__select--stretch", { 'form__select--error': $data.errors && $data.errors.artist_id }]),
                disabled: !!$data.explore_id || !$props.staffList || !$props.staffList.length
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.staffList, (user) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: `submission-${ user.value }`,
                    value: user.value
                  }, _toDisplayString(user.label), 9 /* TEXT, PROPS */, _hoisted_12))
                }), 128 /* KEYED_FRAGMENT */)),
                (!$props.staffList || !$props.staffList.length)
                  ? (_openBlock(), _createElementBlock("option", _hoisted_13, " No artists on project "))
                  : _createCommentVNode("v-if", true)
              ], 10 /* CLASS, PROPS */, _hoisted_11), [
                [_vModelSelect, $data.entry.artist_id]
              ]),
              ($data.entry.artist_id && $props.entries.length > 1)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "form__copy-value",
                    onClick: _cache[5] || (_cache[5] = $event => (_ctx.$emit( 'copy-artist-to-all', $data.entry.artist_id )))
                  }, " Select artist for all "))
                : _createCommentVNode("v-if", true)
            ]))
      ]),
      _createElementVNode("td", null, [
        ($props.canRemove && !$data.upload_complete)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "upload-modal__remove-file",
              onClick: _cache[6] || (_cache[6] = $event => (_ctx.$emit( 'remove-row' )))
            }))
          : ($data.upload_complete)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14))
            : _createCommentVNode("v-if", true)
      ])
    ]),
    ($data.errors)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [
          _createElementVNode("td", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, (error, error_index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `error-${ error_index }`,
                  class: "form__error"
                }, [
                  _createElementVNode("p", null, _toDisplayString(error), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}